import React from 'react';
import PropTypes from 'prop-types';
import {
  List, ListItem, ListItemAvatar, Avatar,
  ListItemText, ListItemSecondaryAction, Badge, Tooltip, Button,
} from '@material-ui/core';
import {
  Description as DescriptionIcon,
} from '@material-ui/icons';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';

import moment from 'moment';
import 'moment/locale/ar-ma';
import 'moment/locale/fr';

import useStyles from './styles';
import Form from './Form';

import { Text } from '../../components';
import { useAuth } from '../../AuthContext';
import { translator, api } from '../../utils';

const { translate, language, direction } = translator;

const excerpt = (str, max = 45) => {
  let r = str;
  if (str.length > max) {
    r = `${str.substring(0, max)}...`;
  }
  return r;
};

function DocumentList({
  title,
  max,
  search,
  tag,
  folder,
}) {
  const classes = useStyles();
  const { profile } = useAuth();
  const [list, setList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState();
  const [isReadOnly, setIsReadOnly] = React.useState(true);
  const lang = language() === 'ar' ? 'ar-ma' : language();

  const fetchList = () => {
    api.searchDocuments({
      max,
      mode: 'tree',
      search,
      tag,
      folder,
    }).then((result) => {
      setList(result);
    });
  };

  const handleSelect = (id) => {
    setSelected(id);
    setOpen(true);
  };

  const closeForm = () => {
    setOpen(false);
    setSelected(undefined);
    fetchList();
  };

  React.useEffect(() => {
    fetchList();
  }, [search, tag, max, folder]);

  React.useEffect(() => {
    setIsReadOnly(!profile.permissions.includes('ARCHIVE'));
  }, [profile]);

  return (
    <>
      <div className={classes.title}>
        <Text variant="h6">{title}</Text>
        {
          profile.permissions.includes('ARCHIVE') && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
              startIcon={<CreateNewFolderIcon />}
            >
              {translate('New Document')}
            </Button>
          )
        }
      </div>
      <List dense>
        {list.map(({
          title: docTitle, description, _id, files, createdAt,
        }) => (
          <ListItem
            button
            key={_id}
            style={{ borderBottom: '1px solid' }}
          >
            <ListItemAvatar>
              <Avatar>{`${docTitle}`.substr(0, 1).toUpperCase()}</Avatar>
            </ListItemAvatar>
            <ListItemText
              onClick={() => handleSelect(_id)}
              dir={direction()}
              primary={docTitle}
              secondary={excerpt(description)}
            />
            {moment(createdAt).locale(lang).fromNow()}
            <ListItemSecondaryAction>
              <Tooltip title={`${files.length} ${translate('File(s)')}`}>
                <Badge badgeContent={files.length} color="secondary">
                  <DescriptionIcon />
                </Badge>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Form readOnly={isReadOnly} open={open || selected !== undefined} onClose={closeForm} selected={selected} />
    </>
  );
}

DocumentList.propTypes = {
  title: PropTypes.string,
  search: PropTypes.string,
  tag: PropTypes.string,
  folder: PropTypes.string,
  max: PropTypes.number,
};

DocumentList.defaultProps = {
  title: 'Recent / Search Result',
  search: '',
  folder: '',
  tag: undefined,
  max: undefined,
};

export default DocumentList;
