import React from 'react';
import PropTypes from 'prop-types';

import SyncIcon from '@material-ui/icons/Sync';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloneIcon from '@material-ui/icons/FileCopy';

import {
  useHistory,
} from 'react-router-dom';

import Swal from 'sweetalert2';

import { Table } from '../../../components';
import { services, translator, cancelableRequest } from '../../../utils';

const { translate } = translator;

const List = ({
  plural, single, headers, service, query,
}) => {
  const history = useHistory();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    const req = cancelableRequest(services[service].find(query));
    req.promise
      .then((d) => {
        setData(d);
        setLoading(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        if (!e.isCanceled) console.error(e);
      });
    return req.cancel;
  }, [service, query]);

  React.useEffect(() => {
    const cancel = fetchData();
    return () => {
      cancel();
    };
  }, [fetchData]);

  const handleDelete = (row) => {
    Swal.fire({
      title: translate('Please confirm!'),
      html: `
          ${translate('This action will delete the')} ${single} :
          <strong style="color: red;">
            ${row.name}
          </strong> ${translate('permanently')}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: translate('Confirm'),
      cancelButtonText: translate('Cancel'),
      showLoaderOnConfirm: true,
      preConfirm: () => services[service].remove(row._id)
        .catch((error) => {
          Swal.showValidationMessage(
            `${translate('Request failed:')} ${error}`,
          );
        }),
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        fetchData();
        Swal.fire(
          translate('Deleted!'),
          translate('The record has been deleted.'),
          'success',
        );
      }
    });
  };

  return (
    <>
      <Table
        title={`${translate('List of')} ${plural}`}
        headers={headers}
        orderBy="name"
        data={data}
        loading={loading}
        actions={[
          {
            label: translate('Delete'),
            icon: <DeleteIcon />,
            onClick: handleDelete,
          }, {
            label: translate('Edit'),
            icon: <EditIcon />,
            onClick: ({ _id }) => history.push(`/${service}/form/${_id}`),
          }, {
            label: translate('Clone'),
            icon: <CloneIcon />,
            onClick: ({ _id }) => history.push(`/${service}/form/${_id}/clone`),
          }, {
            label: translate('Refresh'),
            icon: <SyncIcon />,
            global: true,
            onClick: fetchData,
          }]}
      />
    </>
  );
};

List.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  service: PropTypes.string.isRequired,
  plural: PropTypes.string.isRequired,
  single: PropTypes.string.isRequired,
  query: PropTypes.shape({}),
};

List.defaultProps = {
  query: {},
};

export default List;
