import React from 'react'; // File has too many lines (382). Maximum allowed is 200
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Toolbar, Typography, IconButton, Tooltip, TextField,
} from '@material-ui/core';

import { useToolbarStyles } from './styles';

const EnhancedTableToolbar = ({
  title, actions, onFilter, ...props
}) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected}
          {' '}
          selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      )}

      <TextField
        label="Filter"
        margin="dense"
        fullWidth
        variant="outlined"
        onChange={({ target }) => onFilter(target.value)}
      />

      {actions.map((a) => (
        <Tooltip key={`global-actions-item-key-${a.label}`} title={a.label}>
          <IconButton aria-label={`${a.label}`.toLowerCase()} onClick={a.onClick}>
            {a.icon}
          </IconButton>
        </Tooltip>
      ))}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string,
  numSelected: PropTypes.number.isRequired,
  onFilter: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
};
EnhancedTableToolbar.defaultProps = {
  title: '',
  actions: [],
};

export default EnhancedTableToolbar;
