/* eslint-disable react/prop-types */
import React from 'react';
import {
  FormControlLabel, Switch, Box, Typography,
} from '@material-ui/core';

import Template from '../template';
import { translator } from '../../utils';

const { translate } = translator;

const FormField = ({ value, onChange }) => {
  const [permissions, setPermissions] = React.useState(new Set());

  const handleChange = (permission) => (evt, isSet) => {
    const nSet = new Set(permissions);
    if (isSet) nSet.add(permission);
    else nSet.delete(permission);
    onChange({ target: { value: Array.from(nSet) } });
  };

  React.useEffect(() => {
    if (Array.isArray(value)) setPermissions(new Set(value));
  }, [value]);

  return (
    <>
      <Typography variant="subtitle1">{ translate('Permissions') }</Typography>
      <Box p={2}>
        <FormControlLabel
          control={(
            <Switch
              checked={permissions.has('ADMIN')}
              onChange={handleChange('ADMIN')}
              color="secondary"
            />
            )}
          label="ADMIN"
        />
        <FormControlLabel
          control={(
            <Switch
              checked={permissions.has('ARCHIVE')}
              onChange={handleChange('ARCHIVE')}
              color="secondary"
            />
            )}
          label="ARCHIVE"
        />
      </Box>
    </>
  );
};

const headers = [
  {
    id: 'name', numeric: false, disablePadding: true, label: translate('Name'), required: true,
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: translate('Description'),
  },
  {
    id: 'permissions',
    numeric: false,
    disablePadding: true,
    label: translate('Permissions'),
    defaultValue: [],
    renderer: (v) => v.join(', '),
    FormField,
  },
];

export default () => <Template plural={translate('Groups')} noForm single={translate('Group')} {...{ headers }} />;
