import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, getLuminance } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { translator, services, cancelableRequest } from '../../utils';

const { translate } = translator;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const colors = {
  // delete: '#f7e9e9',
  delete: '#f96c6c',
  // update: '#e3fde4',
  update: '#79ff7e',
  // create: '#dff1ff',
  create: '#6abeff',
};

const getLabel = (action) => (
  <span style={{
    backgroundColor: colors[action],
    padding: '3px 4px',
    borderRadius: 4,
    color: getLuminance(colors[action]) > 0.5 ? '#000' : '#fff',
  }}
  >
    {translate(action)}
  </span>
);

const Events = ({ max }) => {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);

  const fetchData = React.useCallback(() => {
    const req = cancelableRequest(services.activities.find({ max }));
    req.promise
      .then((d) => {
        setRows(d);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        if (!e.isCanceled) console.error(e);
      });
    return req.cancel;
  }, []);

  React.useEffect(() => {
    const cancel = fetchData();
    return () => {
      cancel();
    };
  }, [fetchData]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="activities table">
          <TableHead>
            <TableRow>
              <TableCell>{translate('Date')}</TableCell>
              <TableCell>{translate('User')}</TableCell>
              <TableCell>{translate('Action')}</TableCell>
              <TableCell>{translate('Resource')}</TableCell>
              <TableCell>{translate('Resource Name')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row._id} className={classes.row}>
                {/* style={{ background: colors[row.action] }} */}
                <TableCell size="medium" component="th" scope="row">
                  {new Date(row.createdAt).toLocaleString()}
                </TableCell>
                <TableCell>{translate(row.user.name)}</TableCell>
                <TableCell>{getLabel(row.action)}</TableCell>
                <TableCell>{translate(row.schema)}</TableCell>
                <TableCell>{row.resource.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

Events.propTypes = {
  max: PropTypes.number,
};

Events.defaultProps = {
  max: 1000,
};

export default Events;
