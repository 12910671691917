import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  red, teal, deepOrange,
} from '@material-ui/core/colors';
import {
  ThemeProvider, createTheme, StylesProvider, jssPreset,
} from '@material-ui/core/styles';

import { create } from 'jss';
import rtl from 'jss-rtl';

import App from './App';
import { AuthProvider } from './AuthContext';
import { LangProvider, useLang } from './LangContext';
import { translator } from './utils';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const EntryPoint = () => {
  const { language } = useLang();
  const [theme, setTheme] = useState();

  React.useEffect(() => {
    const direction = translator.direction(language);
    document.body.style.direction = direction;
    const nTheme = createTheme({
      direction,
      palette: {
        // type: 'dark',
        primary: teal,
        secondary: deepOrange,
        error: red,
      },
    });
    setTheme(nTheme);
  }, [language]);

  if (!theme) return '';
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <AuthProvider>
        <CssBaseline />
        {
          theme.direction === 'rtl'
            ? (
              <StylesProvider jss={jss}>
                <App />
              </StylesProvider>
            )
            : <App />
        }
      </AuthProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(
  <LangProvider>
    <EntryPoint />
  </LangProvider>,
  document.querySelector('#root'),
);
