import React from 'react';
import PropTypes from 'prop-types';

import {
  LinearProgress, Tooltip,
} from '@material-ui/core';

const CellContent = ({ column: { label, renderer, excerpt }, data }) => {
  const [loading, setLoading] = React.useState(true);
  const [content, setContent] = React.useState(data);

  React.useEffect(() => {
    if (renderer === undefined || typeof renderer !== 'function') {
      setLoading(false);
      return;
    }
    (async () => {
      setLoading(true);
      try {
        const c = await renderer(data);
        // renderer
        setContent(
          Array.isArray(c) ? c.join(', ') : c,
        );
        setLoading(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    })();
  }, [renderer, data]); // Or [] if effect doesn't need props or state

  return (
    <>
      {
        loading
          ? (
            <div style={{ paddingRight: 15 }}>
              <LinearProgress />
            </div>
          )
          : (
            <Tooltip title={content} aria-label={label}>
              <div>
                {(excerpt && typeof content === 'string' && content.length > 50)
                  ? `${content.substring(0, 50)}...`
                  : content}
              </div>
            </Tooltip>
          )
      }
    </>
  );
};

CellContent.propTypes = {
  column: PropTypes.shape({
    label: PropTypes.string,
    renderer: PropTypes.func,
    excerpt: PropTypes.bool,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.any)]),
};
CellContent.defaultProps = {
  data: '',
};

export default CellContent;
