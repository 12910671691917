import React from 'react';
import { Paper, Grid, Box } from '@material-ui/core';
import { MainHeader } from '../../components';
import {
  Search, DocumentsList, Tags, Folders,
} from '../../containers';

import useStyles from './styles';

const Documents = () => {
  const classes = useStyles();
  const [search, setSearch] = React.useState('');
  const [tag, setTag] = React.useState('');
  const [folder, setFolder] = React.useState('');

  return (
    <>
      <MainHeader
        title="Documents"
      />
      <Paper>
        <Box pt={2} p={1} m={1}>
          <Search onChange={setSearch} />
        </Box>
      </Paper>
      <Grid container spacing={2}>
        <Grid item sm={12} md={3}>
          <Paper className={classes.paper}>
            <Folders onClick={setFolder} highlighted={folder} />
          </Paper>
        </Grid>
        <Grid item sm={12} md={6}>
          <Paper className={classes.paper}>
            <DocumentsList
              title="Documents list"
              search={search}
              tag={tag}
              folder={folder}
            />
          </Paper>
        </Grid>
        <Grid item sm={12} md={3}>
          <Paper className={classes.paper}>
            <Tags onClick={setTag} highlighted={tag} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Documents;
