import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableContainer,
  TablePagination, TableRow, Paper, IconButton, Tooltip,
} from '@material-ui/core';

import Loader from '../Loader';
import { stableSort, getComparator } from './utils';
import { useStyles } from './styles';
import CellContent from './CellContent';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';

function EnhancedTable({
  title, actions, data, headers: propHeadCells, loading,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [headCells, setHeadCells] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filter = (txt) => {
    const reg = new RegExp(`.*${txt}.*`, 'gi');
    const keys = Object.keys(data[0]);
    const tmpRows = data.filter(
      (_row) => {
        for (let index = 0; index < keys.length; index += 1) {
          const key = keys[index];
          if (reg.test(_row[key])) return true;
        }
        return false;
      },
    );
    setRows(tmpRows);
  };

  React.useEffect(() => setRows(data), [data]);
  React.useEffect(() => {
    setHeadCells(
      propHeadCells.filter(({ hideColumn }) => hideColumn !== true),
    );
  }, [propHeadCells]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          onFilter={filter}
          numSelected={selected.length}
          title={title}
          actions={actions.filter((a) => a.global === true)}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row._id)}
                      // role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell className={classes.actionsClmn}>
                        {actions.filter((i) => i.global !== true).map((a) => (
                          <Tooltip key={`actions-item-key-${a.label}`} title={a.label}>
                            <IconButton aria-label={`${a.label}`.toLowerCase()} onClick={(evt) => a.onClick(row, evt)}>
                              {a.icon}
                            </IconButton>
                          </Tooltip>
                        ))}
                      </TableCell>
                      {
                        headCells.map((headRow, idx) => (
                          <TableCell
                            component={idx === 0 ? 'th' : 'td'}
                            id={labelId}
                            key={`${labelId}-${headRow.id}`}
                            scope="row"
                            padding="none"
                          >
                            <CellContent column={headRow} data={row[headRow.id]} />
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 10000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {
        loading && <div className={classes.loading}><Loader /></div>
      }
    </div>
  );
}

EnhancedTable.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  headers: PropTypes.arrayOf(PropTypes.shape({})),
};

EnhancedTable.defaultProps = {
  title: '',
  data: [],
  headers: [],
  actions: [],
  loading: false,
};

export default EnhancedTable;
