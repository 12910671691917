import React from 'react';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Loader from './components/Loader';
import SignIn from './SignIn';
import Layout from './Layout';
import { useAuth } from './AuthContext';

export default function App() {
  const { isAuthenticated, isLoading } = useAuth();

  // eslint-disable-next-line no-nested-ternary
  return isLoading ? <Loader text="Checking authentication... " />
    : isAuthenticated ? (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Layout />
      </MuiPickersUtilsProvider>
    ) : <SignIn />;
}
