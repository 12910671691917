import api from './api';

class Service {
  constructor(name) {
    this.name = name;
  }

  create(data) {
    return (
      api.client(`/${this.name}`, {
        method: 'POST',
        body: JSON.stringify(data),
      })
    );
  }

  get(id) {
    return (
      api.client(`/${this.name}/${id}`)
    );
  }

  patch(id, data) {
    return (
      api.client(`/${this.name}/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })
    );
  }

  remove(id) {
    return (
      api.client(`/${this.name}/${id}`, {
        method: 'DELETE',
      })
    );
  }

  find(params = {}) {
    const searchParams = new URLSearchParams();
    Object.entries(params).map(([k, v]) => searchParams.append(k, v));
    return api.client(`/${this.name}?${searchParams.toString()}`);
  }
}

const servicesList = {
  tags: new Service('tags'),
  users: new Service('users'),
  groups: new Service('groups'),
  folders: new Service('folders'),
  activities: new Service('events'),
};

export default servicesList;
