import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    '& h6': {
      flexGrow: 1,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px',
  },
  titleText: {
    flexGrow: 1,
    padding: 'unset',
  },

  tree: {
    '& > ul, & > ul ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },

    '& > ul ul': {
      marginLeft: 20,
    },

    '& > ul li': {
      margin: 0,
      paddingLeft: 7,
      // lineHeight: '20px',
      // fontWeight: 'bold',
      borderLeft: '1px solid rgb(100, 100, 100)',
    },

    '& > ul li:last-child': {
      borderLeft: 'none',
    },

    '& > ul li:before': {
      position: 'relative',
      top: ' -0.3em',
      height: '1.2em',
      width: '12px',
      color: 'white',
      borderBottom: '1px solid rgb(100, 100, 100)',
      content: '""',
      display: 'inline-block',
      left: -7,
    },

    '& > ul li:last-child:before': {
      borderLeft: '1px solid rgb(100, 100, 100)',
    },
  },
}));
