/* eslint-disable react/prop-types */
import React from 'react';
import {
  TextField, FormControl,
  InputLabel, InputAdornment, Input, IconButton,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  Visibility, VisibilityOff,
} from '@material-ui/icons';
import Template from '../template';
import { translator, services } from '../../utils';

const { translate } = translator;

const headers = [
  {
    id: 'name', numeric: false, disablePadding: true, label: translate('Full Name'), required: true,
  },
  {
    id: 'email', numeric: false, disablePadding: true, label: translate('Email'), required: true, type: 'email',
  },
  {
    id: 'password',
    numeric: false,
    hideColumn: true,
    disablePadding: true,
    label: translate('Password'),
    required: true,
    FormField: ({ value, onChange, label }) => {
      const [showPass, setShowPass] = React.useState(false);
      return (
        <FormControl fullWidth>
          <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
          <Input
            id="standard-adornment-password"
            type={!showPass ? 'password' : 'text'}
            value={value}
            onChange={onChange}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPass((b) => !b)}
                  onMouseDown={() => setShowPass((b) => !b)}
                >
                  {!showPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )}
          />
        </FormControl>
      );
    },
  },
  {
    id: 'groups',
    numeric: false,
    disablePadding: true,
    label: translate('Groups'),
    defaultValue: [],
    renderer: (v) => v.map(({ name }) => name).join(', '),
    FormField: ({ label, value, onChange }) => {
      const [options, setOptions] = React.useState([]);
      const [selection, setSelection] = React.useState([]);
      const [loading, setLoading] = React.useState(true);

      const handleChange = (evt, data) => {
        const ids = data.map(({ _id }) => _id);
        onChange({ target: { value: ids } });
      };

      React.useEffect(() => {
        if (options.length > 0) {
          const s = options.filter(({ _id }) => value.includes(_id));
          setSelection(s);
        }
      }, [value, options]);

      React.useEffect(() => {
        services.groups.find()
          .then((groups) => {
            setOptions(groups);
            setLoading(false);
          });
      }, []);

      return (
        <Autocomplete
          multiple
          options={options}
          getOptionLabel={(option) => option.name}
          loading={loading}
          onChange={handleChange}
          value={selection}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={label}
            />
          )}
        />
      );
    },
  },
];

export default () => <Template plural={translate('Users')} single={translate('User')} {...{ headers }} />;
