/* eslint-disable no-async-promise-executor */
import React from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery, useTheme,
  Dialog, DialogActions, DialogContent, DialogTitle,
  List, ListItem, ListItemText, ListItemSecondaryAction, CircularProgress,
  FormControlLabel, TextField, Grid, Switch, Button, IconButton, Divider,
} from '@material-ui/core';
import {
  Save as SaveIcon, Cancel as CancelIcon,
  CloudDownload as DownloadIcon, Delete as DeleteIcon,
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { KeyboardDatePicker } from '@material-ui/pickers';
import Swal from 'sweetalert2';

import { Text, Loader } from '../../components';
import { api, translator, services } from '../../utils';
import { useAuth } from '../../AuthContext';
import useStyles from './styles';

const { translate } = translator;
// eslint-disable-next-line react/prop-types
const AsyncIconButton = ({ onClick, children }) => {
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);
    onClick().finally(() => setLoading(false));
  };

  return (
    <IconButton
      edge="end"
      aria-label="download"
      onClick={handleClick}
    >
      {
        loading
          ? <CircularProgress size={20} />
          : children
      }
    </IconButton>
  );
};
const FormDialog = ({
  open, onClose, selected, readOnly,
}) => {
  const classes = useStyles();
  const { profile: { organization: { metadata } } } = useAuth();
  const formRef = React.useRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [dragOver, setDragOver] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({ metadata: {}, tags: [] });
  const [tags, setTags] = React.useState([]);
  const [folders, setFolders] = React.useState([]);

  React.useEffect(async () => {
    if (selected) {
      setLoading(true);
      const doc = await api.getDocument(selected);
      setData(doc);
      setLoading(false);
    }
  }, [selected]);

  const handleChange = (key, isMeta, isFile) => (evt, selections) => {
    const nData = { ...data };
    if (!isMeta) {
      if (isFile) nData[key] = evt.target.files;
      else {
        // eslint-disable-next-line no-nested-ternary
        nData[key] = Array.isArray(selections)
          ? selections.map(({ _id }) => _id)
          // eslint-disable-next-line no-nested-ternary
          : typeof selections === 'object'
            ? selections
              ? selections._id
              : undefined
            : evt.target.value;
      }
    } else {
      const { type } = metadata[key];
      switch (type) {
        case 'date':
          nData.metadata[key] = evt;
          break;
        case 'boolean':
          nData.metadata[key] = evt.target.checked;
          break;
        default:
          nData.metadata[key] = evt.target.value;
      }
    }
    setData(nData);
  };

  const submit = async () => {
    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return;
    }
    setLoading(true);
    // const payload = {
    //   ...data,
    //   tags: data.tags.map(({ _id }) => _id),
    // };
    try {
      await api.saveDocument(data);
      onClose();
      Swal.fire({
        position: 'bottom-end',
        icon: 'success',
        title: translate('Operation Succeeded!'),
        showConfirmButton: false,
        timer: 1000,
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: translate('Error'),
        text: translate(err.message),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    await api.deleteDocument(selected);
    onClose();
  };

  React.useEffect(async () => {
    if (!open) {
      setLoading(false);
      setDragOver(false);
      setData({ metadata: {}, tags: [] });
    } else {
      const _tags = await services.tags.find();
      setTags(_tags);
      const _folders = await services.folders.find();
      setFolders(_folders);
    }
  }, [open]);

  return (
    <>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={onClose}
        aria-labelledby="documents-form"
      >
        <div className={classes.titleContainer}>
          <DialogTitle id="documents-form-title" className={classes.titleText}>
            {translate('Documents Form')}
          </DialogTitle>
          {
            selected && !readOnly && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
                style={{ backgroundColor: '#f30404', color: 'white' }}
              >
                {translate('Delete')}
              </Button>
            )
          }
        </div>
        <DialogContent
          className={dragOver ? classes.drop : ''}
          onDragOver={() => setDragOver(true)}
          onDragLeave={() => setDragOver(false)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <form ref={formRef}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField
                      required
                      fullWidth
                      variant="filled"
                      value={data.title || ''}
                      label={translate('Title')}
                      onChange={handleChange('title')}
                      inputProps={{
                        readOnly,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={translate('Description')}
                      fullWidth
                      multiline
                      minRows={3}
                      variant="filled"
                      value={data.description || ''}
                      onChange={handleChange('description')}
                      inputProps={{
                        readOnly,
                      }}
                    />
                  </Grid>
                  {
                Object.entries(metadata).map(([key, { label, type, isRequired }]) => (
                  <Grid key={key} item>
                    {
                      type === 'string'
                      && (
                        <TextField
                          label={label}
                          fullWidth
                          multiline
                          required={isRequired}
                          variant="filled"
                          value={data.metadata[key] || ''}
                          onChange={handleChange(key, true)}
                          inputProps={{
                            readOnly,
                          }}
                        />
                      )
                    }
                    {
                      type === 'date'
                      && (
                        <KeyboardDatePicker
                          label={label}
                          required={isRequired}
                          value={data.metadata[key]}
                          fullWidth
                          allowKeyboardControl={false}
                          inputVariant="filled"
                          onChange={handleChange(key, true)}
                          format="yyyy-MM-DD"
                          readOnly={readOnly}
                          autoOk
                        />
                      )
                    }
                    {
                      type === 'boolean'
                      && (
                        <FormControlLabel
                          control={(
                            <Switch
                              checked={data.metadata[key] || false}
                              onChange={handleChange(key, true)}
                              color="primary"
                            />
                          )}
                          label={label}
                        />

                      )
                    }
                  </Grid>
                ))
              }
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Text variant="h6">Folder</Text>
              <Divider style={{ marginBottom: 10 }} />
              <Autocomplete
                disabled={readOnly}
                options={folders}
                getOptionLabel={(option) => option.name || ''}
                onChange={handleChange('folder')}
                fullWidth
                value={folders.find(({ _id }) => data.folder === _id) || {}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate('Parent Folder')}
                    variant="filled"
                  />
                )}
              />
              <Text variant="h6">Tags</Text>
              <Divider style={{ marginBottom: 10 }} />
              <Autocomplete
                multiple
                disabled={readOnly}
                options={tags}
                getOptionLabel={(option) => option.name}
                onChange={handleChange('tags')}
                fullWidth
                value={tags.filter(({ _id }) => data.tags.includes(_id))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate('Document Tags')}
                    variant="filled"
                  />
                )}
              />
              <Text variant="h6">Files</Text>
              <List dense>
                {data.files && data.files.map(({ uuid, name, documentId }, index) => (
                  <ListItem key={uuid}>
                    <ListItemText
                      primary={name}
                    />
                    <ListItemSecondaryAction>
                      <AsyncIconButton onClick={() => new Promise(async (res) => {
                        // eslint-disable-next-line no-alert,no-restricted-globals
                        if (confirm('Please confirm')) {
                          await api.deleteFile(documentId, uuid);
                          data.files.splice(index, 1);
                          setData({ ...data });
                        }
                        res();
                      })}
                      >
                        <DeleteIcon />
                      </AsyncIconButton>
                      <AsyncIconButton onClick={() => api.downloadFile(documentId, uuid, name)}>
                        <DownloadIcon />
                      </AsyncIconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
                {
                  (!data.files || data.files.length === 0) && (
                    <ListItem>
                      <ListItemText
                        primary={translate('No files available')}
                        secondary={translate('If you think this is a mistake, please contact your adminitrators')}
                      />
                    </ListItem>
                  )
                }
              </List>
              {
                !readOnly && (
                  <>
                    <Divider style={{ marginBottom: 10 }} />
                    <TextField
                      label={translate('Select files')}
                      fullWidth
                      type="file"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange('newfiles', false, true)}
                      inputProps={{
                        readOnly,
                        multiple: 'multiple',
                      }}
                    />
                  </>
                )
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="default" startIcon={<CancelIcon />}>
            {translate('Close')}
          </Button>
          {
            !readOnly && (
              <Button onClick={submit} variant="contained" color="primary" startIcon={<SaveIcon />}>
                {translate('Save')}
              </Button>
            )
          }
        </DialogActions>
        { loading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 5,
              fontSize: '1.5em',
              backgroundColor: 'rgb(197 197 197 / 45%)',
            }}
          >
            <Loader />
          </div>
        )}
      </Dialog>
    </>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  selected: PropTypes.string,
};

FormDialog.defaultProps = {
  selected: undefined,
};

export default FormDialog;
