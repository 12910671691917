import React from 'react';
import { MainHeader } from '../../components';
import { Events } from '../../containers';
import { translator } from '../../utils';

const { translate } = translator;

export default () => (
  <>
    <MainHeader title={translate('Activities')} />
    <Events />
  </>
);
