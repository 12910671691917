/* eslint-disable no-async-promise-executor */
import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  useMediaQuery, useTheme, TextField, Button, Grid,
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import {
  Save as SaveIcon, Cancel as CancelIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import Swal from 'sweetalert2';

import { Loader } from '../../components';
import { services, translator } from '../../utils';
import useStyles from './styles';

const Api = services.tags;

const { translate } = translator;

const FormDialog = ({ open, onClose, selected }) => {
  const classes = useStyles();
  const formRef = React.useRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [list, setList] = React.useState([]);
  const [readOnly] = React.useState(false);

  React.useEffect(async () => {
    if (selected) {
      const doc = await Api.get(selected);
      setData(doc);
    }
  }, [selected]);

  const handleChange = (key) => (evt, value) => {
    let { name, parent } = data;

    if (key === 'parent') parent = value;
    else name = evt.target.value;

    setData((d) => ({ ...d, name, parent }));
  };

  const submit = async () => {
    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return;
    }
    setLoading(true);
    try {
      const payload = {
        ...data,
        parent: data.parent ? data.parent._id : undefined,
      };
      if (payload._id) await Api.patch(payload._id, payload);
      else await Api.create(payload);
      onClose();
      Swal.fire({
        position: 'bottom-end',
        icon: 'success',
        title: translate('Operation Succeeded!'),
        showConfirmButton: false,
        timer: 1000,
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: translate('Error'),
        text: translate(err.message),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    await Api.remove(selected);
    onClose();
  };

  React.useEffect(() => {
    if (!open) {
      setLoading(false);
      setData({});
    }
  }, [open]);

  React.useEffect(async () => {
    const tags = await Api.find();
    setList(tags);
    setLoading(false);
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        onClose={onClose}
        aria-labelledby="documents-form"
      >
        <div className={classes.titleContainer}>
          <DialogTitle id="documents-form-title" className={classes.titleText}>
            {translate('Tags')}
          </DialogTitle>
          {
            selected && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
                style={{ backgroundColor: '#f30404', color: 'white' }}
              >
                {translate('Delete')}
              </Button>
            )
          }
        </div>
        <DialogContent>
          <form ref={formRef}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  required
                  fullWidth
                  variant="filled"
                  value={data.name || ''}
                  label={translate('Name')}
                  onChange={handleChange('name')}
                  inputProps={{
                    readOnly,
                  }}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  options={list}
                  getOptionLabel={(option) => option.name}
                  onChange={handleChange('parent')}
                  fullWidth
                  value={data.parent || { name: '' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate('Parent')}
                      variant="filled"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="default" startIcon={<CancelIcon />}>
            {translate('Close')}
          </Button>
          <Button onClick={submit} variant="contained" color="primary" startIcon={<SaveIcon />}>
            {translate('Save')}
          </Button>
        </DialogActions>
        { loading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 5,
              fontSize: '1.5em',
              backgroundColor: 'rgb(197 197 197 / 45%)',
            }}
          >
            <Loader />
          </div>
        )}
      </Dialog>
    </>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

FormDialog.defaultProps = {
  selected: undefined,
};

export default FormDialog;
