import { makeStyles } from '@material-ui/core/styles';

import { translator } from '../../utils';

const { translate } = translator;

export default makeStyles(() => ({
  title: {
    display: 'flex',
    alignItems: 'center',

    '& h6': {
      flexGrow: 1,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px',
  },
  titleText: {
    flexGrow: 1,
    padding: 'unset',
  },
  drop: {
    '&:before': {
      content: `"${translate('Drop files')}"`,
      fontSize: '5em',
      position: 'absolute',
      background: '#ffffff1c',
      backdropFilter: 'blur(2px)',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      zIndex: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '5px dashed currentColor',
    },
  },
}));
