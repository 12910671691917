import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Toolbar,
  Button, Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 10,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const MainHeader = ({ title, actions }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <AppBar
        color={theme.palette.type === 'dark' ? 'default' : 'primary'}
        position="static"
        elevation={1}
      >
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {actions.map((a) => (
            <Button
              {...a}
              key={`main-header-button-${a.label}`}
              color="inherit"
            >
              {a.label}
            </Button>
          ))}
        </Toolbar>
      </AppBar>
    </div>
  );
};

MainHeader.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
};

MainHeader.defaultProps = {
  title: '',
  actions: [],
};

export default MainHeader;
