import React from 'react';
import {
  Button, Menu, MenuItem,
} from '@material-ui/core';
import { Translate } from '@material-ui/icons';
import { translator } from '../../utils';
import { useLang } from '../../LangContext';

export default function LanguageSwitch() {
  const { language, setLanguage } = useLang();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // React.useEffect( () => console.log(language), [language]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (lg) => () => {
    translator.setLanguate(lg);
    setLanguage(translator.language());
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="language-menu"
        aria-haspopup="true"
        startIcon={<Translate />}
        onClick={handleClick}
      >
        { translator.translate(language.toUpperCase(), language) }
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose('en')} selected={language === 'en'}>
          { translator.translate('EN', language) }
        </MenuItem>
        <MenuItem onClick={handleClose('fr')} selected={language === 'fr'}>
          { translator.translate('FR', language) }
        </MenuItem>
        <MenuItem onClick={handleClose('ar')} selected={language === 'ar'}>
          { translator.translate('AR', language) }
        </MenuItem>
      </Menu>
    </div>
  );
}
