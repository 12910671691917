const text = require('../config/text.json');

const translator = {
  language: () => (localStorage.getItem('lang') ? localStorage.getItem('lang') : navigator.language.split('-')[0]),
  setLanguate: (lg) => {
    if (text[lg]) localStorage.setItem('lang', lg);
  },
  direction: (lang) => {
    const lg = lang || translator.language();
    if (!text[lg]) throw new Error(`Language: ${lg} not supported`);
    return text[lg].direction;
  },
  translate: (str, lang = null) => {
    const lg = lang || translator.language();
    if (!text[lg]) throw new Error(`Language: ${lg} not supported`);
    const translation = text[lg].texts[str];
    if (translation) return translation;
    if (text[lg].default === true) return str;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('Missing Translation in:', lg, str);
    }
    return str;
  },
};

export default translator;
