import React from 'react';

import { translator } from './utils';

const LangContext = React.createContext();

function LangProvider(props) {
  const [language, setLanguage] = React.useState(translator.language());

  const value = React.useMemo(() => [language, setLanguage], [language, setLanguage]);

  return <LangContext.Provider value={value} {...props} />;
}

function useLang() {
  const context = React.useContext(LangContext);
  if (!context) throw new Error('useAuth must be used within a LangProvider');

  const [language, setLanguage] = context;

  return {
    language,
    setLanguage,
  };
}

export { LangProvider, useLang };
