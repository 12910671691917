/* eslint-disable react/prop-types */
import React from 'react';
import {
  InputAdornment, TextField, Button,
  // Switch, FormControlLabel,
} from '@material-ui/core';
import {
  Search as SearchIcon,
} from '@material-ui/icons';
import { translator } from '../../utils';

const { translate } = translator;

export default function Search({ onChange }) {
  const [text, setText] = React.useState('');
  const handleChange = ({ target: { value } }) => setText(value);

  return (
    <>
      <TextField
        fullWidth
        value={text}
        size="small"
        label={translate('Search')}
        placeholder={translate('Type your search criteria')}
        // value={value}
        onChange={handleChange}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button
                onClick={() => onChange(text)}
                size="small"
                variant="contained"
                startIcon={<SearchIcon />}
              >
                {translate('Search')}
              </Button>
            </InputAdornment>
          ),
        }}
      />
      {/* <FormControlLabel
        control={<Switch name="checkedA" />}
        label={translate('Advanced Search')}
      /> */}
    </>
  );
}
