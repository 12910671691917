/* eslint-disable no-await-in-loop */
/* eslint-disable no-async-promise-executor */
const urlJoin = (...args) => args
  .join('/')
  // eslint-disable-next-line no-useless-escape
  .replace(/[\/]+/g, '/')
  .replace(/^(.+):\//, '$1://')
  .replace(/^file:/, 'file:/')
  .replace(/\/(\?|&|#[^!])/g, '$1')
  .replace(/\?/g, '&')
  .replace('&', '?');

const normalizeQueryOptions = (options = {}) => {
  let { headers } = options;
  if (!headers) headers = { 'Content-Type': 'application/json' };
  const token = localStorage.getItem('jwt');
  if (token) headers.authorization = `Bearer ${token}`;
  try {
    headers = {
      'x-request-id': `FE-${Math.random().toString(36).substring(2, 11).toUpperCase()}`,
      ...headers,
    };
  } catch (err) { /* this mean invalid custom headers were provided */ }
  return {
    ...options,
    headers,
  };
};

const apiURL = process.env.REACT_APP_API_URL;

const client = (path, options = {}, isFile = false) => new Promise((resolve, reject) => {
  const url = new URL(
    urlJoin(apiURL, path),
    window.location,
  );
  fetch(url, normalizeQueryOptions(options))
    .then(async (response) => {
      if (isFile) {
        const blob = await response.blob();
        resolve(blob);
        return;
      }
      const text = await response.text();
      let body = text;
      try {
        body = JSON.parse(body);
        // eslint-disable-next-line no-empty
      } catch (err) {}
      if (response.status >= 200 && response.status < 300) resolve(body);
      if (response.status >= 400 && response.status < 500) reject(body);
    }).catch((error) => {
      reject(error);
    });
});

const api = {
  loadProfile: () => client('/profile'),

  login: (data) => new Promise((res, rej) => {
    client('/login', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(({ token }) => {
        localStorage.setItem('jwt', token);
        res(token);
      }).catch((err) => rej(err));
  }),

  logout: () => {
    localStorage.removeItem('jwt');
    window.location.reload();
  },

  searchDocuments(params = {}) {
    const searchParams = new URLSearchParams();
    Object.entries(params).map(([k, v]) => searchParams.append(k, v));
    return api.client(`/documents?${searchParams.toString()}`);
  },

  saveDocument: (data) => new Promise(async (res, rej) => {
    const document = { ...data, files: undefined, newfiles: undefined };
    try {
      const { _id: docId } = await client(`/documents${data._id ? `/${data._id}` : ''}`, {
        method: data._id ? 'PATCH' : 'POST',
        body: JSON.stringify(document),
      });
      if (data.newfiles instanceof FileList) await api.uploadFiles(data.newfiles, docId);
      res();
    } catch (err) { rej(err); }
  }),

  getDocument: (id) => client(`/documents/${id}`),

  deleteDocument: async (docId) => client(`/documents/${docId}`, {
    method: 'DELETE',
  }),

  uploadFiles: (files, docId) => new Promise(async (res, rej) => {
    try {
      for (let index = 0; index < files.length; index += 1) {
        const file = files[index];
        const formData = new FormData();
        formData.append('file', file, file.name);
        await client(`/documents/${docId}/files`, {
          method: 'POST',
          headers: {},
          body: formData,
        });
      }
      res();
    } catch (err) { rej(err); }
  }),

  downloadFile: async (docId, fileId, filename) => {
    const blob = await client(`/documents/${docId}/files/${fileId}/download`, {}, true);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  },

  deleteFile: async (docId, fileId) => client(`/documents/${docId}/files/${fileId}`, {
    method: 'DELETE',
  }),

  client,
};

export default api;
