import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@keyframes rotate': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  mask: {
    backgroundColor: '#20202073',
    height: '100%',
    width: '100%',
    display: 'block',
    position: 'absolute',
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    width: '100vw',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',

    '& > span': {
      position: 'relative',
    },

    '& > span:after': {
      animation: '$rotate 2s linear infinite',
      content: '""',
      position: 'absolute',
      top: -15,
      left: -35,
      display: 'block',
      width: 30,
      height: 30,
      border: '1px solid',
      borderColor: `${theme.palette.primary.main} transparent`,
      borderRadius: '50%',
    },

    '& > span:before': {
      animation: '$rotate 1s linear infinite reverse',
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -10,
      left: -30,
      width: 20,
      height: 20,
      border: '1px solid',
      borderColor: `transparent ${theme.palette.primary.main}`,
      borderRadius: '50%',
    },

    '& > div': {
      margin: 5,
    },
  },
}));

// eslint-disable-next-line react/prop-types
export default function App({ text = 'Loading...', mask = false }) {
  const classes = useStyles();
  return (
    <>
      { mask && <span className={classes.mask} /> }
      <div className={classes.loader}>
        <span />
        <div>{text}</div>
      </div>
    </>
  );
}
