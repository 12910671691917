import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Typography,
  TextField, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  useParams,
  useHistory,
} from 'react-router-dom';

import Swal from 'sweetalert2';

import Loader from '../../../components/Loader';
import { services, translator } from '../../../utils';

const { translate } = translator;

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    padding: 15,

    '&:after': {
      content: "''",
      display: 'block',
      clear: 'both',
    },
  },
  form: {
    overflow: 'auto',
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#0000006b',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    zIndex: 1,
  },
  field: {
    marginBottom: 10,
  },
  button: {
    margin: 10,
    float: 'right',
  },
}));

const Form = ({
  single, headers, service, SubForm,
}) => {
  const classes = useStyles();
  const Api = services[service];
  const { id, clone } = useParams();
  const history = useHistory();

  const [data, setData] = React.useReducer(
    (o, n) => ({ ...o, ...n }),
    {},
    () => headers.reduce((v, { id: index, defaultValue = '' }) => ({
      ...v,
      [index]: defaultValue,
    }),
    {}),
  );
  const [loading, setLoading] = React.useState(false);
  const [isNew] = React.useState(id === undefined || clone === 'clone');

  const goToList = () => history.push(`/${service}/list`);

  const submitHandler = (evt) => {
    evt.preventDefault();
    const promise = isNew
      ? Api.create({
        ...data,
      })
      : Api.patch(data._id, {
        ...data,
      });

    promise.then(() => {
      Swal.fire({
        position: 'bottom-end',
        icon: 'success',
        title: translate('Operation Succeeded!'),
        showConfirmButton: false,
        timer: 1000,
      });
      goToList();
    }).catch(({ result }) => {
      Swal.fire({
        icon: 'error',
        title: translate('Error'),
        text: translate(result),
      });
    });
  };

  React.useEffect(() => {
    if (!id) return;
    setLoading(true);
    Api.get(id)
      .then(({ _id, ...d }) => {
        setData({
          ...d,
          ...(isNew ? {} : { _id }),
        });
      })
      .finally(() => setLoading(false));
  }, [id, Api, isNew]);

  const onChange = (idx, value) => setData({ [idx]: value });

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">
        {
          isNew
            ? `${translate('Create a new:')} ${single}`
            : `${translate('Edit')} ${single}: ${data.name}`
        }
      </Typography>
      <form onSubmit={submitHandler} autoComplete="off" className={classes.form}>
        {
          headers.map(({
            // eslint-disable-next-line no-shadow
            id, label, required = false, multiline = false,
            transform, FormField = undefined, editable = true, type = 'text',
          }) => {
            const Field = FormField || TextField;
            return (
              <Field
                className={classes.field}
                key={`${service}-form-field-${id}`}
                label={label}
                required={required}
                type={type}
                fullWidth
                disabled={editable === false && !isNew}
                multiline={multiline}
                value={data[id]}
                onChange={({ target: { value } }) => setData({ [id]: transform ? transform(value) : value })}
              />
            );
          })
        }
        { SubForm && <SubForm setLoading={(b) => setLoading(b)} data={data} onChange={onChange} /> }
        <Button className={classes.button} variant="outlined" color="inherit" onClick={() => goToList()}>
          {translate('Cancel')}
        </Button>
        <Button type="sumbit" className={classes.button} variant="contained" color="primary">
          {translate('Sumbit')}
        </Button>
        { loading && <div className={classes.loading}><Loader /></div> }
      </form>
    </Paper>
  );
};

Form.propTypes = {
  SubForm: PropTypes.func,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  service: PropTypes.string.isRequired,
  single: PropTypes.string.isRequired,
};

Form.defaultProps = {
  SubForm: undefined,
};

export default Form;
