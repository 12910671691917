import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DashboardIcon from '@material-ui/icons/Dashboard';
import FolderIcon from '@material-ui/icons/Folder';
import UsersIcon from '@material-ui/icons/AccountBox';
import PeopleIcon from '@material-ui/icons/People';
// import TagsIcon from '@material-ui/icons/LocalOffer';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { useHistory } from 'react-router-dom';

import { translator } from '../utils';
import { useLang } from '../LangContext';
import { useAuth } from '../AuthContext';

export default () => {
  const { language } = useLang();
  const { profile } = useAuth();
  const history = useHistory();
  // const { profile: { permissions } } = useAuth();

  const [currentLocation, setCurrentLocation] = React.useState(history.location.pathname);

  const goTo = (path) => () => {
    history.push(path);
    setCurrentLocation(path);
  };

  return (
    <div>
      <List>
        <ListItem button onClick={goTo('/')} selected={currentLocation === '/'}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={translator.translate('Dashboard', language)} />
        </ListItem>
        <ListItem button onClick={goTo('/documents')} selected={currentLocation === '/documents'}>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText primary={translator.translate('Documents', language)} />
        </ListItem>
        {
          profile.permissions.includes('ADMIN') && (
            <>
              <ListItem button onClick={goTo('/users')} selected={currentLocation === '/users'}>
                <ListItemIcon>
                  <UsersIcon />
                </ListItemIcon>
                <ListItemText primary={translator.translate('Users', language)} />
              </ListItem>
              <ListItem button onClick={goTo('/groups')} selected={currentLocation === '/groups'}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={translator.translate('Groups')} />
              </ListItem>
            </>
          )
        }

        {/* <ListItem button onClick={goTo('/tags')} selected={currentLocation === '/tags'}>
          <ListItemIcon>
            <TagsIcon />
          </ListItemIcon>
          <ListItemText primary={translator.translate('Tags')} />
        </ListItem> */}
        <ListItem button onClick={goTo('/activities')} selected={currentLocation === '/activities'}>
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText primary={translator.translate('Activities')} />
        </ListItem>
      </List>
    </div>
  );
};
