import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { MainHeader } from '../../components';
import DefaultList from './List';
import DefaultForm from './Form';
import { translator } from '../../utils';

function Template({
  plural, single, Form, List, headers, query,
}) {
  const history = useHistory();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const [isForm, setIsForm] = React.useState(false);
  const [subProps] = React.useState({
    headers,
    plural,
    single,
    service: `${path}`.replace('/', ''),
    query,
    SubForm: Form,
  });

  React.useEffect(() => {
    setIsForm(location.pathname !== `${path}/list`);
  }, [location.pathname, path]);

  return (
    <>
      <MainHeader
        title={plural}
        actions={[{
          startIcon: !isForm ? <AddIcon /> : <CloseIcon />,
          label: translator.translate(!isForm ? 'Add' : 'Close'),
          onClick: () => { history.push(`${url}/${isForm ? 'list' : 'form'}`); },
        }]}
      />

      <Switch>
        <Route path={`${path}/form/:id?/:clone?`}>
          <DefaultForm {...subProps} />
        </Route>
        <Route path={`${path}/list`}>
          <List {...subProps} />
        </Route>
        <Route exact path={path}>
          <Redirect to={`${path}/list`} />
        </Route>
      </Switch>
    </>
  );
}

Template.propTypes = {
  plural: PropTypes.string.isRequired,
  single: PropTypes.string.isRequired,
  Form: PropTypes.func,
  List: PropTypes.func,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  query: PropTypes.shape({}),
};

Template.defaultProps = {
  Form: undefined,
  List: DefaultList,
  headers: [],
  query: {},
};

export default Template;
