import React from 'react';

import { api } from './utils';

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [isAuthenticated, setAuthenticated] = React.useState();
  const [profile, setProfile] = React.useState({ permissions: [] });
  const [isLoading, setLoading] = React.useState(true);

  const value = React.useMemo(() => [
    isAuthenticated, profile, isLoading, setProfile,
  ],
  [isAuthenticated, profile, isLoading, setProfile]);

  React.useEffect(() => {
    if (
      profile === undefined
      || Object.keys(profile).length === 0
    ) return;
    setAuthenticated(true);
  }, [profile]);

  React.useEffect(() => {
    api.loadProfile()
      .then((user) => {
        setAuthenticated(true);
        setProfile(user);
      })
      .catch(() => {
        setAuthenticated(false);
      }).finally(() => setLoading(false));
    // client.reAuthenticate().then(({ user }) => {
    //   setAuthenticated(true);
    //   setProfile(user);
    // }).catch(() => {
    //   setAuthenticated(false);
    // }).finally(() => setLoading(false));
  }, []);

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within a AuthProvider');

  const [isAuthenticated, profile, isLoading, setProfile] = context;

  return {
    profile,
    isLoading,
    setProfile,
    isAuthenticated,
  };
}

export { AuthProvider, useAuth };
