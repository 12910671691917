import React from 'react';
import {
  Grid, Paper, Typography,
} from '@material-ui/core';
import { translator } from '../../utils';
import { useLang } from '../../LangContext';
import { useAuth } from '../../AuthContext';
import { DocumentsList, Events } from '../../containers';

import useStyles from './styles';

const { translate } = translator;

export default function AutoGrid() {
  const { language } = useLang();
  const { profile } = useAuth();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md>
          <Paper className={classes.paper}>
            <Typography variant="h4">{ translate('Dashboard', language) }</Typography>
            <Typography component="span" color="primary">{ profile.name }</Typography>
            {' of '}
            <Typography component="span" color="secondary">
              { profile.groups.map(({ name }) => name).join(', ') }
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md>
          <Paper className={classes.paper}>
            <DocumentsList max={10} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md>
          <Events max={10} />
          {/* <Paper className={classes.paper}>Tags</Paper> */}
        </Grid>
      </Grid>
    </div>
  );
}
