/* eslint-disable no-restricted-globals */
import React from 'react';
import clsx from 'clsx';
import {
  Tooltip, Badge, AppBar, CssBaseline, Divider,
  Hidden, IconButton, Toolbar, Typography, Drawer,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  PowerSettingsNew,
  Menu as MenuIcon,
} from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
  BrowserRouter as Router,
  Switch, Route,
} from 'react-router-dom';
import { Copyright, LanguageSwitch } from '../components';

import ListItems from './ListItems';
import client from '../utils/api';

import {
  Activities, Documents, Groups, Tags, Users, Dashboard,
} from '../pages';
import { useAuth } from '../AuthContext';
import { translator } from '../utils';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      ...(theme.direction === 'ltr' ? { marginLeft: drawerWidth } : { marginRight: drawerWidth }),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
}));

function Layout(props) {
  const { profile: { name, organization } } = useAuth();
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <ListItems />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <Router>
        <CssBaseline />
        <AppBar
          color={theme.palette.type === 'dark' ? 'default' : 'primary'}
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              { organization.name }
            </Typography>
            <Typography>
              { `${translator.translate('Hello')}, ${name}` }
            </Typography>
            <LanguageSwitch />
            <Tooltip title="Se déconnecter">
              <IconButton color="inherit" onClick={client.logout}>
                <Badge color="secondary">
                  <PowerSettingsNew />
                </Badge>
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="menu">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route path="/documents">
              <Documents />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/groups">
              <Groups />
            </Route>
            <Route path="/tags">
              <Tags />
            </Route>
            <Route path="/Activities">
              <Activities />
            </Route>
            <Route exact path="/">
              <Dashboard />
            </Route>
          </Switch>
          <Copyright />
        </main>
      </Router>
    </div>
  );
}

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

Layout.defaultProps = {
  window: undefined,
};

export default Layout;
