import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Tooltip,
} from '@material-ui/core';

import {
  CreateNewFolder as CreateNewFolderIcon,
  Folder as FolderIcon,
} from '@material-ui/icons';

import Form from './Form';
import useStyles from './styles';
import { useAuth } from '../../AuthContext';
import { Text } from '../../components';
import { translator, services } from '../../utils';

const { translate } = translator;

const Api = services.folders;

const generateList = (data, clickHandler, dbClickHandler, highlighted) => (
  <ul>
    {data.map(({ _id, name, children }) => (
      <li key={_id}>
        <Tooltip
          title={name}
          placement="left"
          aria-label={name.toLowerCase()}
        >
          <Button
            onClick={() => {
              clickHandler(
                _id === highlighted ? undefined : _id,
              );
            }}
            onDoubleClick={() => dbClickHandler(_id)}
            size="small"
            variant={highlighted === _id ? 'outlined' : 'text'}
            style={{ margin: '2px 0' }}
            startIcon={<FolderIcon />}
          >
            {name}
          </Button>
        </Tooltip>
        {children && generateList(children, clickHandler, dbClickHandler, highlighted)}
      </li>
    ))}
  </ul>
);

const FoldersContainer = ({ highlighted, onClick }) => {
  const classes = useStyles();
  const [list, setList] = React.useState([]);
  const { profile } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState();
  const [isReadOnly, setIsReadOnly] = React.useState(true);

  const dbClickHandler = (id) => {
    setSelected(id);
    setOpen(true);
  };

  const handleClick = (id) => onClick(id);

  const fetchList = async () => {
    const data = await Api.find({ tree: '' });
    setList(data);
  };

  const closeForm = () => {
    setOpen(false);
    setSelected(undefined);
    fetchList();
  };

  React.useEffect(() => {
    fetchList();
  }, []);

  React.useEffect(() => {
    setIsReadOnly(!profile.permissions.includes('ARCHIVE'));
  }, [profile]);

  return (
    <>
      <div className={classes.title}>
        <Text variant="h6">Folders</Text>
        {
          !isReadOnly && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(true)}
              startIcon={<CreateNewFolderIcon />}
            >
              {translate('Folder')}
            </Button>
          )
        }
      </div>
      <div className={classes.tree}>
        {generateList(list, handleClick, dbClickHandler, highlighted)}
      </div>
      {
        !isReadOnly && <Form open={open || selected !== undefined} onClose={closeForm} selected={selected} />
      }
    </>
  );
};

// highlighted, onClick
FoldersContainer.propTypes = {
  highlighted: PropTypes.string,
  onClick: PropTypes.func,
};

FoldersContainer.defaultProps = {
  onClick: () => {},
  highlighted: '',
};

export default FoldersContainer;
